import { Box } from "@mui/material";
import React from "react";

type NumberContentProps = {
  number: number;
};

const NumberContent = ({ number }: NumberContentProps) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      flexGrow={1}
      sx={{
        backgroundColor: "#fafafa",
      }}>
      <em
        style={{
          fontSize: "4em",
          fontStyle: "normal",
          fontWeight: "bold",
        }}>
        {number}
      </em>
    </Box>
  );
};

export default NumberContent;
