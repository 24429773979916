import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import DashboardSettingsModalContet from "./DashboardSettingsModalContet";
import { WidgetSetting } from "types/analytics/widgetSetting";

type DashboardSettingsProps = {
  setWidgetSettings: (items: WidgetSetting[] | undefined) => void;
  widgetSettings?: WidgetSetting[];
  isSettingsOpen: boolean;
  setIsSettingsOpen: (value: boolean) => void;
  onSaveWidgets: (widgets?: WidgetSetting[]) => Promise<void>;
};

const DashboardSettings = ({
  widgetSettings,
  setWidgetSettings,
  isSettingsOpen,
  setIsSettingsOpen,
  onSaveWidgets,
}: DashboardSettingsProps) => {
  const onSettingsClick = async () => {
    setIsSettingsOpen(true);
  };

  const onModalClose = () => {
    setIsSettingsOpen(false);
  };

  const onWidgetSettingUpdated = (item: WidgetSetting, newValue: boolean) => {
    if (!widgetSettings) return;

    const updatedItems = widgetSettings.map((widget) =>
      widget.id === item.id ? { ...widget, isVisible: newValue } : widget
    );

    setWidgetSettings(updatedItems);
  };

  const onSave = async () => {
    await onSaveWidgets(widgetSettings);
    onModalClose();
  };

  return (
    <>
      <Tooltip title="Dashboard Settings" placement="left">
        <IconButton aria-label="settings" onClick={onSettingsClick}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        dialogTitle="Dashboard Settings"
        content={
          <DashboardSettingsModalContet
            items={widgetSettings}
            onWidgetSettingsChange={onWidgetSettingUpdated}
            onWidgetsReorder={setWidgetSettings}
          />
        }
        isOpen={isSettingsOpen}
        onCancel={onModalClose}
        onConfirm={onSave}
        cancelText="Close"
        confirmText="Save"
      />
    </>
  );
};

export default DashboardSettings;
