import React, { useEffect, useState } from "react";
import { Paper, Grid, CircularProgress, Box, Button } from "@mui/material";
import DataListContent from "./DataListContent";
import DataTableContent from "./DataTableContent";
import NumberContent from "./NumberContent";
import AnalyticsApi from "api/AnalyticsApi";
import WidgetActionsDropdown from "./WidgetActionsDropdown";
import WidgetReloadButton from "./WidgetReloadButton";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";

type WidgetProps = {
  dataItem: WidgeDataItem;
};

type WidgeDataItem = {
  actions: string[];
  description: string;
  name: string;
  type: string;
  value: any;
};

const Widget = ({ dataItem }: WidgetProps) => {
  const [item, setItem] = useState(dataItem);

  const [loading, setLoading] = useState(false);

  const handleReload = () => {
    if (item) {
      setLoading(true);
      AnalyticsApi.getDataItem(item.name)
        .then((data) => setItem(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => handleReload(), []);

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", flexGrow: "1", minHeight: "400px" }}>
      <Box display={"flex"} flexDirection={"column"} flexGrow={1} padding={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: "#474486",
              }}>
              {item?.description}
            </h3>
          </Grid>
          <Grid item>
            <WidgetReloadButton onReload={handleReload} />
            <WidgetActionsDropdown dataItem={item} />
            <Button>
              <ControlCameraIcon />
            </Button>
          </Grid>
        </Grid>
        {loading && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {!loading && item?.type === "DataTable" && <DataTableContent tableInfo={item.value} />}
        {!loading && item?.type === "DataList" && <DataListContent data={item.value} />}
        {!loading && item?.type === "Number" && <NumberContent number={item.value} />}
      </Box>
    </Paper>
  );
};

export default Widget;
