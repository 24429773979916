import React, { ChangeEvent, forwardRef } from "react";
import { Box, CircularProgress, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { WidgetSetting } from "types/analytics/widgetSetting";
import { ReactSortable } from "react-sortablejs";
import DragHandleIcon from "@mui/icons-material/DragHandle";

// eslint-disable-next-line react/display-name
const CustomComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <Grid display={"flex"} flexDirection={"column"} ref={ref}>
      {props.children}
    </Grid>
  );
});

type SwitchLabelProps = {
  title: string;
};

const SwitchLabel = ({ title }: SwitchLabelProps) => {
  return (
    <Box display={"flex"} gap={1}>
      <DragHandleIcon />
      <span>{title}</span>
    </Box>
  );
};

type DashboardSettingsModalContetProps = {
  items?: WidgetSetting[];
  onWidgetSettingsChange?: (item: WidgetSetting, newValue: boolean) => void;
  onWidgetsReorder?: (items: WidgetSetting[]) => void;
};
const DashboardSettingsModalContet = ({
  items,
  onWidgetSettingsChange,
  onWidgetsReorder: onWidgetsReoirder,
}: DashboardSettingsModalContetProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>, item: WidgetSetting) => {
    if (!items) return;

    onWidgetSettingsChange?.(item, event.target.checked);
  };

  return !items ? (
    <CircularProgress />
  ) : (
    <>
      <Box textAlign={"center"}>
        <Typography>Please select the widgets you want to display on the dashboard.</Typography>
        <Typography mb={2} fontSize={14}>
          <em>You can drag and drop to reorder them.</em>
        </Typography>
      </Box>
      <ReactSortable list={items} setList={onWidgetsReoirder} tag={CustomComponent}>
        {items.map((widget) => (
          <FormControlLabel
            sx={{ my: 1, justifyContent: "space-between" }}
            key={widget.id}
            control={
              <Switch
                color="primary"
                checked={widget.isVisible}
                onChange={(e) => handleChange(e, widget)}
              />
            }
            label={<SwitchLabel title={widget.widgetDescription} />}
            labelPlacement="start"
          />
        ))}
      </ReactSortable>
    </>
  );
};

export default DashboardSettingsModalContet;
