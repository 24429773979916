import { WidgetSetting } from "types/analytics/widgetSetting";
import { msalApiFetch } from "../commons/authConfig";
import { AnalyticsDataItem } from "types/analytics/analyticsDataItem";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "analytics";

export default {
  getData(): Promise<{
    items: AnalyticsDataItem[];
    query: { periodEnd: string; periodStart: string };
  }> {
    const url = `${baseUrl}/${endpoint}/data`;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getDataItem(itemName: string) {
    const url = `${baseUrl}/${endpoint}/data/${itemName}`;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getAvailableWidgets(): Promise<WidgetSetting[]> {
    const url = `${baseUrl}/widgets/getUserSettings`;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  updateWidgetsStatus(widgets: WidgetSetting[]) {
    const visibleWidgets = widgets.filter((widget) => widget.isVisible);
    const hiddenWidgets = widgets.filter((widget) => !widget.isVisible);

    const orderedWidgets = visibleWidgets
      .concat(hiddenWidgets)
      .map((widget, index) => ({ ...widget, order: index }));

    const url = `${baseUrl}/widgets/saveUserSettings`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderedWidgets),
    }).then((data) => {
      if (!data.ok) throw data;
    });
  },
};
