import React, { forwardRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, CircularProgress } from "@mui/material";
import AnalyticsApi from "api/AnalyticsApi";
import Widget from "./components/Widget";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { AnalyticsDataItem } from "types/analytics/analyticsDataItem";
import DashboardSettings from "./components/DashboardSettings";
import { WidgetSetting } from "types/analytics/widgetSetting";
import { ReactSortable } from "react-sortablejs";

// eslint-disable-next-line react/display-name
const CustomComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingBottom: 2,
      }}
      ref={ref}>
      {props.children}
    </Grid>
  );
});

const Dashboard = () => {
  const [widgetSettings, setWidgetSettings] = useState<WidgetSetting[] | undefined>();
  const [dataItems, setDataItems] = useState<AnalyticsDataItem[] | null>(null);
  const visibleDataItems = dataItems?.filter((x) => x.visible) ?? [];
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "BPP Overview" });
  }, []);

  function updateWidgets(
    availableWidgets: WidgetSetting[],
    dataItems: { items: AnalyticsDataItem[] }
  ) {
    let availableWidgetsWithId = availableWidgets.map((item, index) => ({
      ...item,
      id: item.widgetName,
      order: index + 1,
    }));

    const visibleWidgets = dataItems.items.map((x) => ({
      ...x,
      visible: availableWidgetsWithId
        .filter((y) => y.isVisible)
        .some((y) => y.widgetName === x.name),
    }));

    // order visible widgets by the order of the widget settings
    visibleWidgets.sort(
      (a, b) =>
        availableWidgetsWithId.find((x) => x.widgetName === a.name)!.order -
        availableWidgetsWithId.find((x) => x.widgetName === b.name)!.order
    );

    setDataItems(visibleWidgets);

    availableWidgetsWithId = availableWidgetsWithId.filter((x) =>
      dataItems.items.some((y) => y.name === x.widgetName)
    );

    setWidgetSettings(availableWidgetsWithId);
  }

  const loadWidgets = async () => {
    const [availableWidgets, dataItemsApi] = await Promise.all([
      AnalyticsApi.getAvailableWidgets(),
      AnalyticsApi.getData(),
    ]);

    updateWidgets(availableWidgets, dataItemsApi);
  };

  const onSaveWidgets = async (newWidgetsSettings?: WidgetSetting[]) => {
    if (!newWidgetsSettings) {
      return;
    }

    await AnalyticsApi.updateWidgetsStatus(newWidgetsSettings);

    updateWidgets(newWidgetsSettings, { items: dataItems! });
  };

  useEffect(() => {
    loadWidgets();
  }, []);

  const handleReorder = async (newItems: AnalyticsDataItem[]) => {
    setDataItems(newItems);

    if (!widgetSettings) {
      return;
    }

    const newOrder = newItems.map((item) => item.name);
    const oldOrder = dataItems?.map((item) => item.name) ?? [];

    const isDifferentOrder = newOrder.some((item, index) => item !== oldOrder[index]);

    if (!isDifferentOrder) {
      return;
    }

    const newOrderedWidgets = widgetSettings
      .map((widget) => {
        const widgetData = newItems.find((x) => x.name === widget.widgetName);
        const widgetOrder = newItems.findIndex((x) => x.name === widget.widgetName) ?? 0;

        return {
          ...widget,
          isVisible: widgetData?.visible ?? false,
          order: widgetOrder + 1,
        };
      })
      .sort((a, b) => a.order - b.order);

    await onSaveWidgets(newOrderedWidgets);
  };

  return (
    <div className="view-container">
      <Box display={"flex"} justifyContent={"flex-end"}>
        <DashboardSettings
          isSettingsOpen={isSettingsOpen}
          setIsSettingsOpen={setIsSettingsOpen}
          widgetSettings={widgetSettings}
          setWidgetSettings={setWidgetSettings}
          onSaveWidgets={onSaveWidgets}
        />
      </Box>
      {dataItems == null ? (
        <CircularProgress />
      ) : (
        <>
          <ReactSortable list={dataItems} setList={handleReorder} tag={CustomComponent}>
            {visibleDataItems.map((dataItem) => (
              <Grid
                key={dataItem.name}
                item
                xs={6}
                md={4}
                sx={{ display: "flex", flexDirection: "column" }}>
                <Widget dataItem={dataItem} />
              </Grid>
            ))}
          </ReactSortable>
        </>
      )}
    </div>
  );
};

export default Dashboard;
